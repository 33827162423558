<template>
  <v-container class="mt-10 my-2 mx-2 px-2 col-md-12">
    <v-row justify="center" dropzone="" class="mt-5">
      <v-dialog v-model="dialogs" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            add scama
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span>add new sc</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-col cols="12">
                <v-text-field
                  label="name"
                  v-model="name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="server1"
                  v-model="link1"
                  required
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                  <v-text-field
                    label="server2"
                    v-model="link2"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="server3"
                    v-model="link3"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="server4"
                    v-model="link4"
                    required
                  ></v-text-field>
                </v-col>
               
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      label="langserver1"
                      placeholder="langserver1"
                      filled
                      :items="lang"
                      item-text="title"
                      item-value="lgs"
                      rounded
                      :rules="nameRules"
                      v-model="sr1"
                      prepend-icon="mdi-compass"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      label="langserver2"
                      placeholder="langserver2"
                      filled
                      :items="lang"
                      item-text="title"
                      item-value="lgs"
                      rounded
                      :rules="nameRules"
                      v-model="sr2"
                      prepend-icon="mdi-compass"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      label="langserver3"
                      placeholder="langserver3"
                      filled
                      :items="lang"
                      item-text="title"
                      item-value="lgs"
                      rounded
                      :rules="nameRules"
                      v-model="sr3"
                      prepend-icon="mdi-compass"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      label="langserver4"
                      placeholder="langserver4"
                      filled
                      :items="lang"
                      item-text="title"
                      item-value="lgs"
                      rounded
                      :rules="nameRules"
                      v-model="sr4"
                      prepend-icon="mdi-compass"
                      dense
                    ></v-select>
                  </v-col> 
                </v-row>-->
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogs = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="addSc"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

 

    <div class="text-center col-md-5 ma-5">
      <v-card>
        <p>add new letter</p>

        <v-container fluid>
          <tinymce id="d1" v-model="text"></tinymce>
          <!-- <v-textarea
    v-model="text"
      name="input-7-1"
      filled
      label="Label"
      auto-grow
        ></v-textarea> -->
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addLetter"> send letter </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div v-if="!dataUsers.length">
      <div class="ma-2" v-for="g in 5" :key="g">
        <v-flex xs12 md10 sm10 justify-center>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </v-flex>
      </div>
    </div>

    <div v-else>
      <!-- <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="10"
        class="elevation-1"
      ></v-data-table> -->
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">id</th>
              <th class="text-center">users</th>
              <th class="text-center">email</th>
              <th class="text-center">country</th>
              <th class="text-center">ip</th>
              <th class="text-center">victims</th>
              <th class="text-center">Last victims</th>
              <th class="text-center">date Create</th>
              <th class="text-center">Last Login</th>
              <th class="text-center">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, items) in dataUsers" :key="items">
              <td class="text-center">{{ item.user.id }}</td>
              <td class="text-center">
                <router-link
                  :to="{
                    name: 'Profile',
                    params: { id: item.user.id },
                  }"
                  >{{ item.user.username_u }}</router-link
                >
              </td>
              <td class="text-center">
                {{ item.user.email }}
              </td>
              <td class="text-center">{{ item.user.country }}</td>
              <td class="text-center">{{ item.user.ip }}</td>
              <td class="text-center">{{ item.allvictims }}</td>

              <td class="text-center">
                <timeago
                  class="float-center"
                  :datetime="item.updatedAt"
                  :auto-update="60"
                ></timeago>
              </td>
              <td class="text-center">
                <timeago
                  class="float-center"
                  :datetime="item.user.createdAt"
                  :auto-update="60"
                ></timeago>
              </td>
              <td class="text-center">
                <timeago
                  class="float-center"
                  :datetime="item.user.updatedAt"
                  :auto-update="60"
                ></timeago>
              </td>
              <td class="text-center">
                <v-btn
                  rounded
                  text
                  fab
                  x-small
                  @click="blockAcoount(item.user.id)"
                  color="red"
                >
                  <v-icon>mdi-stop</v-icon> update</v-btn
                >
                <v-dialog v-model="dialog" width="500">
                  <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      rounded
                      text
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                    >
                      <v-icon>mdi-stop</v-icon> block</v-btn
                    >
                  </template> -->

                  <v-card>
                    <v-card-text>
                      are you sur block ?
                      <strong class="red--text">{{
                        item.user.usernume_u
                      }}</strong>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        text
                        @click="blockAcoount(item.user.id)"
                      >
                        confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-center">
        <div v-if="loadings">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
        </div>
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="more"
                  class="my-4"
                  :length="pages"
                  @input="morepage"
                  :total-visible="10"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <!-- <th class="text-center">
                  id
                </th> -->
                <th class="text-center">
                  {{ $t("scama.name") }}
                </th>
                <th class="text-center">
                  {{ $t("scama.link") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tablescama"
                v-for="(item, items) in dataScama"
                :key="items"
              >
                <td class="text-center">
                  {{ item.namepage }}
                </td>
                <td class="text-center">
                  <router-link :to="`/wp/edit/${item.id}`">
                    <v-btn color="success" outlined rounded x-small>
                      <v-icon>mdi-link</v-icon>
                      edit
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <!-- <div>jj :  {{dataUsers[0].id}}</div> -->
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      dataUsers: [],
      attrs: "",
      dialogs: false,
      dialogsu: false,
      text: "",
      lang: [
        { title: "enghlish", lgs: "enghlish" },
        { title: "espanish", lgs: "spain" },
        { title: "thailand", lgs: "thailand" },
        { title: "arabic", lgs: "arabic" },
      ],
      name: "",
      link1: "",
      link3: "3",
      link2: "2",
      link4: "4",
      sr1: "en",
      sr2: "en",
      sr3: "en",
      sr4: "en",
      tkn: "Bearer ".concat(this.$store.getters.getToken),
      pages: "",
      more: 1,
      modv: false,
      modl: null,
      itemIndex: null,
      linkfacebook: "",
      linkmessenger: "",
      linkfreefire: "",
      dataScama: [],
    };
  },
  mounted() {
    if(this.$store.getters.getUserId != 1)
    {
      this.$router.push({ name: "First" });
    }
    this.$http
      .get("/letter/simple/1")
      .then((res) => {
        this.linkfacebook = res.data.linkfacebook;
        this.linkmessenger = res.data.linkfmessanger;
        this.linkfreefire = res.data.linkfreefire;
      })
      .catch((err) => {
        this.$notify({
          group: "errors",
          type: "error",
          title: "error",
          text: err,
        });
      });
    this.$http
      .get("/letter/sc/1")
      .then((res) => {
        this.dataScama = res.data.gt;
      })
      .catch((err) => {
        this.$notify({
          group: "errors",
          type: "error",
          title: "error",
          text: err,
        });
      });

    this.$http
      .get("/u/user/" + this.more, {
        headers: {
          Authorization: this.tkn,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.dataUsers = res.data.query;
        this.pages = res.data.pages;
      })
      .catch((err) => {
        this.$notify({
          group: "errors",
          type: "error",
          title: "error",
          text: err,
        });
      });
  },
  methods: {
    updateL: function () {
      this.$http
        .post(
          "/letter/update",
          {
            linkfacebook: this.linkfacebook,
            linkfmessanger: this.linkmessenger,
            linkfreefire: this.linkfreefire,
          },
          {
            headers: {
              Authorization: this.tkn,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.text = "";
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    morepage: function () {
      this.loadings = true;
      this.$http
        .get("/u/user/" + this.more, {
          headers: {
            Authorization: this.tkn,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.loadings = false;

          this.dataUsers = res.data.query;
          this.pages = res.data.pages;
        })
        .catch((err) => {
          console.log(err);
          this.loadings = false;
        });
    },
    addSc() {
      this.$http
        .post(
          "/letter/addsc",
          {
            namelink: this.name,
            link1: this.link1,
            link2: this.link2,
            link3: this.link3,
            link4: this.link4,
            linlang1: this.sr1,
            langlink2: this.sr2,
            langlink3: this.sr3,
            langlink4: this.sr4,
          },
          {
            headers: {
              Authorization: this.tkn,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.text = "";
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    addLetter() {
        if(this.$store.getters.getUserId != 1)
    {
      this.$router.push({ name: "First" });
    }
      this.$http
        .post(
          "/letter",
          {
            text: this.text,
          },
          {
            headers: {
              Authorization: this.tkn,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.text = "";
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    // blockAcoount: function (id) {
    //   this.$http.get("/u/dash/" + id).then((res) => {
    //     this.$notify({
    //       group: "success",
    //       type: "success",
    //       title: "success",
    //       text: res.data.message,
    //     });
    //   });
    // },
  },
};
</script>
